import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PlatformFunction } from '../models/PlatformFunction';
import { User } from '../models/User';

const getUserPermission = (permissionList: Array<PlatformFunction>, user?: User | null) => {
    return permissionList?.length > 0 ? permissionList?.filter(p => p.active && user?.permission && user.permission[user.permission.length - p.infoBit] === '1').map(p => p.key) : [];
};

export interface UserState {
    user?: User | null;
    userPermissionKeys: Array<string>;
    permissionList: Array<PlatformFunction>;
}

export const initialState: UserState = {
    user: undefined,
    userPermissionKeys: [],
    permissionList: [],
};

export const userStore = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
            if (action.payload) {
                state.userPermissionKeys = getUserPermission(state.permissionList, action.payload);
                const token = action.payload.token ? action.payload.token : '';
                if (token.length > 0) {
                    localStorage.setItem('token', token);
                }
            }
        },
        logoutUser: (state) => {
            state.user = null;
            state.userPermissionKeys = [];
            localStorage.removeItem('token');
        },
        updatePermissionList: (state, action: PayloadAction<Array<PlatformFunction>>) => {
            state.permissionList = action.payload;
            state.userPermissionKeys = getUserPermission(action.payload, state.user);
        },
    },
});

export const { updateUser, logoutUser, updatePermissionList } = userStore.actions;

export default userStore.reducer;
